<template>
  <div class="position-relative table-responsive table-base">
    <!-- Modal pour ajouter un type de depense -->
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Valider"
      cancel-title="Annuler"
      centered
      title="Ajouter un type de depense"
      @ok="saveTypeDepense"
    >
      <validation-observer ref="registerForm">
       <b-form class="p-2" @submit.prevent>
                  <div>
                    <!-- <h5 class="text-danger text-center">{{ typeDepense_exist }}</h5> -->
                  </div>
                  <!-- libelle -->
                  <b-form-group label="" label-for="register-nom">
                    <label>
                      Libelle <span class="p-0 text-danger h6">*</span>
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="libelle"
                      rules="required"
                    >
                      <b-form-input
                        id="register-nom"
                        @input="validateLibelle"
                        v-model="libelle"
                        name="register-nom"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small
                        :class="valideLibelle? 'block' : 'none'"
                        class="text-danger"
                      >
                        Vous devez renseigner le libellé
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- icône -->
                  <b-form-group label="Icône" label-for="register-nom">
                    <validation-provider
                      #default="{ errors }"
                      name="icone"
                      rules=""
                    >
                      <b-form-input
                        id="register-nom"
                        v-model="icone"
                        name="register-nom"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <!-- <small :class="valideIcone ? 'block' : 'none'" class="text-danger">
                            Vous devez renseigner le libellé du projet
                        </small> -->
                    </validation-provider>
                  </b-form-group>

                  <!-- description -->
                  <b-form-group>
                    <label for="Description">Description</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="description"
                      placeholder=""
                      rows="5"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group> 
                </b-form>
      </validation-observer>
      <!-- <template #modal-footer>
        <b-button
          :disabled="state.loading === true ? true : false"
          variant="primary"
          @click.stop.prevent="handleOk"
        >
          <span v-if="state.loading === false">Ajouter</span>
          <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
        </b-button>
      </template> -->
    </b-modal>

    <!-- Modal pour mettre à jour un compte   -->
    <b-modal
      id="modal-update"
      cancel-variant="outline-secondary"
      ok-title="Modifier"
      cancel-title="Annuler"
      centered
      title="Modifier un compte"
      @ok="updateTypeDepense"
    >
       <b-form class="p-2" @submit.prevent>
                  <div>
                    <!-- <h5 class="text-danger text-center">{{ typeDepense_exist }}</h5> -->
                  </div>
                  <!-- libelle -->
                  <b-form-group label="" label-for="register-nom">
                    <label>
                      Libelle <span class="p-0 text-danger h6">*</span>
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="libelle"
                      rules="required"
                    >
                      <b-form-input
                        id="register-nom"
                        @input="valideEditLibelle"
                        v-model="editLibelle"
                        name="register-nom"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small
                        :class="valideEditLibelle? 'block' : 'none'"
                        class="text-danger"
                      >
                        Vous devez renseigner le libellé
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- icône -->
                  <b-form-group label="Icône" label-for="register-nom">
                    <validation-provider
                      #default="{ errors }"
                      name="icone"
                      rules=""
                    >
                      <b-form-input
                        id="register-nom"
                        v-model="editIcone"
                        name="register-nom"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <!-- <small :class="valideIcone ? 'block' : 'none'" class="text-danger">
                            Vous devez renseigner le libellé du projet
                        </small> -->
                    </validation-provider>
                  </b-form-group>

                  <!-- description -->
                  <b-form-group>
                    <label for="Description">Description</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="editDescription"
                      placeholder=""
                      rows="5"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group> 
                </b-form>
      <!-- <template #modal-footer>
        <b-button
          :disabled="state.loading === true ? true : false"
          variant="primary"
          @click.stop.prevent="updateCompte"
        >
          <span v-if="state.loading === false">Modifer</span>
          <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
        </b-button>
      </template> -->
    </b-modal>

    <b-card no-body class="px-2 ">
      <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->
      <b-row class="mx-2 mb-2">
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
        >
          <label>Entrées</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button variant="primary" v-b-modal.v-b-modal.modal-login>
            <feather-icon icon="PlusIcon" class="mx-auto" />
            Nouveau type de depense
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6" class="mt-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="filtreTypeDepense"
                class="d-inline-block mr-1"
                placeholder="Rechercher par : numero de compte, libelle, date d'ajout..."
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>

      <!-- <q-loader-table
        :success="state.success"
        :empty="state.empty"
        :warring="state.warring"
      /> -->

      <b-table
        hover
        responsive
        primary-key="id"
        :per-page="perPage"
        :current-page="currentPage"
        :items="typeDepenses"
        :fields="tableColumns"
        :filter="filtreTypeDepense"
        show-empty
        empty-text="Aucun type depense enregistrés"
        class="bg-white text-left"
      >
        <!-- v-if="state.success === true" -->
      

    
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap py-1">
            <feather-icon
              v-b-modal.modal-update
              @click="update(data.item)"
              icon="Edit3Icon"
              size="16"
              class="cursor-pointer"
            />

            <feather-icon
              @click="confirmText(data.item.id)"
              icon="TrashIcon"
              class="mx-1 cursor-pointer"
              size="16"
            />

          </div>
        </template>

        <template #cell(created_at)="data" >
          {{ format_date(data.item.created_at) }}
        </template>
      </b-table>

      <!-- Les boutons de pagination -->
      <div class="mx-2 mb-2 pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"></span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="typeDepenseTotal"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- {{__NEXT}} -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/pages/request';
import axios from 'axios';
import moment from 'moment';
import { VueTelInput } from 'vue-tel-input';
import {
  toast_destory,
  toast_error,
  toast_loading,
  toast_sucess,
  toast_update,
} from '@/utils/qToast';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
export default {
  components: {
    moment,
    VueTelInput,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    QLoaderTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // validation
      config: {
        headers: {
          Accept: 'application/json',
        },
      },

      libelle: '',
      valideLibelle: false,

      icone:'',
      description:'',

    
      typeDepenses: [],
      typeDepense_exist: '',

      //validation Update
     
      editLibelle: '',
      valideEditLibelle: false,
      editDescription: '',
      editIcone:'',
      typeDepenseUp: '',
      edit_index: '',

      valeurGood: false,
      valeurEditGood: false,
      returnData: '',
      required,
      valideExiste: false,
      id_update: '',
      updateIndex: '',

      perPage: 30,
      currentPage: 1,
      typeDepenseTotal:0,
      tableColumns: [
          { key: 'libelle',  sortable: true  },
        // { key: 'icone',  sortable: true },
        //  { key: 'description',  sortable: true },
        { key: 'created_at', label: "Date d'ajout" },
        { key: 'actions' },
      ],
      state: {
        loading: false,
        success: false,
        empty: false,
        warring: false,
      },
      filtreTypeDepense: '',
      perPageOptions: [30, 50, 100, 150, 250, 500],
      loadingDelay: 0,
      GIVE: false
    };
  },

  async mounted() {
    document.title = 'Type depense';
   try {
          const config = {
          headers: {
            'Accept': 'application/json'
          },
        }

        await axios
          .get(URL.PARAMETRE_ITEM, config)
          .then((response) => {
            this.typeDepenses = response.data.type_depense_entreprise;
             this.typeDepenseTotal = this.typeDepenses.length
            console.log(this.typeDepenses);
          })
          .catch((error) => {
            console.log(error);
          });
   } catch (error) {
        console.log(error);
   }
  },

  methods: {
   

    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    
   
    validateLibelle() {
      if (!this.libelle) {
        this.valideLibelle = true;
      } else {
        this.valideLibelle = false;
      }
    },

   
    //validationUpdate
   
    validateEditLibelle() {
      if (!this.editLibelle) {
        this.valideEditLibelle = true;
      } else {
        this.valideEditLibelle = false;
      }
    },

   
   
   

    // confirm texrt
    confirmText(id) {
      this.$swal({
        title: `Êtes vous sûr, de supprimer le compte`,
        text: 'Ce compte sera supprimer définitivement !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletetypeDepense(id);
        }
      });
    },
    // top end
    topEnd() {
      this.$swal({
        position: 'top-end',
        icon: 'success',
        title: 'Type depense enregistrée avec succès',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });
    },
    async deletetypeDepense(identifiant) {
      try {
        const id = {
          id: identifiant,
        };
        await axios
          .post(URL.PARAMETRE_DESTROY, id)
          .then((response) => {
            if (response.data) {
            axios
              .get(URL.PARAMETRE_ITEM)
              .then((response) => {
                 this.typeDepenses = response.data.type_depense_entreprise;
                this.typeDepenseTotal = this.typeDepenses.length
              })
              .catch((error) => {
                console.log(error.response.data.errors);
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
            }
          });
      } catch (error) {
        console.log(error.type);
      }
    },
    update(item) {
      this.typeDepenseUp = item;
      this.editLibelle = this.typeDepenseUp.libelle;
      console.log(this.typeDepenseUp);
      this.editDescription = this.typeDepenseUp.description;
      this.editIcone = this.typeDepenseUp.icone;
      this.edit_index = this.typeDepenses.findIndex((el) => el.id === item.id);
      this.id_update = this.typeDepenseUp.id;
    },
    async updateTypeDepense(bvModalEvt) {
      const index = this.edit_index;
      this.validateEditLibelle();
      if (
        this.valideEditLibelle
       
      ) {
        bvModalEvt.preventDefault();
      } else {
        this.$swal({
          title: "Êtes vous sûr de vouloir enregistrer",
          text: "Les modificiations seront prises en compte",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let vide = "";
            try {
              const data = {
                id: this.id_update,
                libelle: this.editLibelle,
                description: this.editDescription,
                icone: this.editIcone,
              };
              const config = {
                headers: {
                  Accept: "application/json",
                },
              };
              axios
                .post(URL.PARAMETRE_UPDATE, data, config)
                .then((response) => {
                  response.data;
                  axios
                    .get(URL.PARAMETRE_ITEM)
                    .then((response) => {
                      this.typeDepenses = response.data.type_depense_entreprise;
                       this.typeDepenseTotal = this.typeDepenses.length
                    })
                    .catch((error) => {
                      console.log(error.response.data.errors);
                    });
                })
                .catch((error) => {
                  if (error.response) {
                    console.log(error.response.data);
                  }
                });

            } catch (error) {
              console.log(error.type);
            }
          }
        });
      }
    },
    // if_exist() {
    //   for (let index = 0; index < this.typeDepenses.length; index++) {
    //     if (this.libelle == this.typeDepenses[index].libelle) {
    //       this.valideExiste = true;
    //       this.typeDepense_exist=
    //         'Ce type depense existe déjà! Veuillez inserer un autre';
    //     } else {
    //       this.valideExiste = false;
    //     }
    //   }
    // },

    //store type depense
    async saveTypeDepense(bvModalEvt) {
      try {
        const config = {
          headers: {
            Accept: "application/json",
          },
        };

        this.validateLibelle();
        // this.if_exist();
        if (this.valideLibelle) {
          bvModalEvt.preventDefault();
          this.handleSubmit();
        } else {
          const data = {
            libelle: this.libelle,
            icone: this.icone,
            description: this.description,
          };
          console.log(data);
          await axios
            .post(URL.PARAMETRE_CREATE, data, config)
            .then((response) => {
              this.topEnd();
              this.parametreData = response.data.depense;
              this.typeDepenses.unshift(this.parametreData);
              this.libelle_depense = "";
              this.icone = "";
              this.description = "";
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}

.none {
  display: none;
}
.block {
  display: inline-block;
}
.red:after {
  content: ' *';
  color: red;
}
</style>
